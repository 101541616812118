/* Bulma Utilities */
.notification:not(:last-child) {
  margin-bottom: 1.5rem;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.notification:not(:last-child) {
  margin-bottom: 1.5rem;
}

.notification {
  background-color: hsl(0deg, 0%, 96%);
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}
.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}
.notification strong {
  color: currentColor;
}
.notification code,
.notification pre {
  background: hsl(0deg, 0%, 100%);
}
.notification pre code {
  background: transparent;
}
.notification > .delete {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.notification .title,
.notification .subtitle,
.notification .content {
  color: currentColor;
}
.notification.is-white {
  background-color: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 4%);
}
.notification.is-black {
  background-color: hsl(0deg, 0%, 4%);
  color: hsl(0deg, 0%, 100%);
}
.notification.is-light {
  background-color: hsl(0deg, 0%, 96%);
  color: rgba(0, 0, 0, 0.7);
}
.notification.is-dark {
  background-color: #999999;
  color: #fff;
}
.notification.is-primary {
  background-color: #d3771f;
  color: #fff;
}
.notification.is-primary.is-light {
  background-color: #fcf5ed;
  color: #b2641a;
}
.notification.is-link {
  background-color: hsl(229deg, 53%, 53%);
  color: #fff;
}
.notification.is-link.is-light {
  background-color: #eff1fa;
  color: #3850b7;
}
.notification.is-info {
  background-color: hsl(207deg, 61%, 53%);
  color: #fff;
}
.notification.is-info.is-light {
  background-color: #eff5fb;
  color: #296fa8;
}
.notification.is-success {
  background-color: hsl(153deg, 53%, 53%);
  color: #fff;
}
.notification.is-success.is-light {
  background-color: #effaf5;
  color: #257953;
}
.notification.is-warning {
  background-color: hsl(44deg, 100%, 77%);
  color: rgba(0, 0, 0, 0.7);
}
.notification.is-warning.is-light {
  background-color: #fffaeb;
  color: #946c00;
}
.notification.is-danger {
  background-color: hsl(348deg, 86%, 61%);
  color: #fff;
}
.notification.is-danger.is-light {
  background-color: #feecf0;
  color: #cc0f35;
}