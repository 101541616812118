/* Bulma Utilities */
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
}
.container.is-fluid {
  max-width: none;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 959px) {
  .container.is-widescreen {
    max-width: 896px;
  }
}
@media screen and (max-width: 1199px) {
  .container.is-fullhd {
    max-width: 1136px;
  }
}
@media screen and (min-width: 960px) {
  .container {
    max-width: 896px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1136px;
  }
}